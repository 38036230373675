<script>
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import storeProfileTypes from '@src/mixins/intelligence/costOfServing/storeProfileTypes'

const i18nKey = 'POS'

export default {
    mixins: [
        vuetableFormatter,
        storeProfileTypes
    ],
    data() {
        return {
            posCostServingChildrenFields: [
                {
                    key: 'external_code',
                    label: this.getI18n(i18nKey, 'FIELDS.external_code'),
                    sortable: true,
                },
                {
                    key: 'name',
                    label: this.getI18n(i18nKey, 'TITLES.pos'),
                    sortable: true,
                },
                {
                    key: 'regional_name',
                    label: this.getI18n(i18nKey, 'TITLES.regional'),
                    sortable: true,
                },
                {
                    key: 'chain_name',
                    label: this.getI18n(i18nKey, 'TITLES.chain'),
                    sortable: true,
                },
                {
                    key: 'ranking',
                    label: this.getI18n(i18nKey, 'IMPORT_FIELDS.ranking'),
                    sortable: true,
                },
                {
                    key: 'profile',
                    label: this.getI18n(i18nKey, 'FIELDS.store_profile'),
                    formatter: this.storeProfileTypeToText,
                    sortable: true,
                },
                {
                    key: 'number_checkout',
                    label: this.getI18n(i18nKey, 'FIELDS.number_checkout'),
                    sortable: true,
                },
                {
                    key: 'revenue',
                    label: this.getI18n(i18nKey, 'FIELDS.revenue'),
                    formatter: this.getI18nCurrency,
                    sortable: true,
                },
                {
                    key: 'number_visits',
                    label: this.getI18n('DASHBOARDS', 'TITLES.number_visits'),
                    sortable: true,
                },
                {
                    key: 'gross_cost',
                    label: this.getI18n('DASHBOARDS', 'TITLES.gross_cost'),
                    formatter: this.getI18nCurrency,
                    sortable: true,
                },
                {
                    key: 'perc_gross_cost',
                    label: `% ${this.getI18n('DASHBOARDS', 'TITLES.gross_cost')}`,
                    formatter: this.formatPercentage,
                    sortable: true,
                },
                {
                    key: 'period_cost',
                    label: this.getI18n('DASHBOARDS', 'TITLES.period_cost'),
                    formatter: this.getI18nCurrency,
                    sortable: true,
                },
                {
                    key: 'perc_period_cost',
                    label: `% ${this.getI18n('DASHBOARDS', 'TITLES.period_cost')}`,
                    formatter: this.formatPercentage,
                    sortable: true,
                },
            ],
        }
    },
}
</script>
