<template>
    <div class="d-flex">
        <v-date-picker
            id="filter-started-at-date-time"
            ref="filterCreatedAtDate"
            v-model="valueProp"
            :class="[childClass, disabled ? 'flex-grow-1 input-date-disabled' : 'flex-grow-1 input-date', disableCleanButton ? 'clear-date-disabled' : '']"
            :locale="$i18n.locale.toLowerCase()"
            :columns="$screens({ default: 1 })"
            :max-date="currentMaxDate"
            :min-date="currentMinDate"
            :popover="{ placement: 'top', visibility: disabled ? 'hidden' : 'focus' }"
            is-range
            :is-required="disableCleanButton"
            @dayclick="handleDayChange"
            @input="handleInput"
        >
            <template v-slot="{ inputValue, inputEvents }">
                <div class="d-flex justify-content-center">
                    <b-form-input
                        class="input-pdv-blue"
                        :value="`${inputValue.start && inputValue.end ? `${inputValue.start} - ${inputValue.end}` : ''}`"
                        :disabled="disabled"
                        v-on="inputEvents.start"
                    />
                    <button
                        v-if="!disableCleanButton"
                        class="btn btn-clean"
                        type="button"
                        :disabled="!valueProp || disabled"
                        @click="valueProp = null">
                        {{ getI18n('COMMON', 'clean')}}
                    </button>
                </div>
            </template>
        </v-date-picker>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        childClass: {
            type: String,
            default: '',
        },
        maxRangeInDays: {
            type: Number,
            required: false,
            default: null
        },
        maxDate: {
            type: Date,
            required: false,
            default: null
        },
        minDate: {
            type: Date,
            required: false,
            default: null
        },
        disableCleanButton: {
            type: Boolean,
            required: false,
        },

    },
    data() {
        return {
            originalMinDate: this.$props.minDate,
            originalMaxDate: this.$props.maxDate,
            currentMinDate: this.$props.minDate,
            currentMaxDate: this.$props.maxDate,
        };
    },
    computed: {
        valueProp: {
            get() { return this.$props.value; },
            set(value) {
                this.$emit('update:value', value);
                this.$emit('onChange', value)
            },
        },
    },
    methods: {
        handleDayChange(day) {
            if ((!day.attributes.length || day.attributes[0].pinPage) && this.$props.maxRangeInDays) {
                this.$nextTick(() => {
                    const min = new Date(day.date.toISOString());
                    const max = new Date(day.date.toISOString());

                    if (Math.round((min - this.originalMinDate) / 86400000) > this.$props.maxRangeInDays)
                        this.currentMinDate = min.setDate(min.getDate() - this.$props.maxRangeInDays);

                    if (Math.round((this.originalMaxDate - max) / 86400000) > this.$props.maxRangeInDays)
                        this.currentMaxDate = max.setDate(max.getDate() + this.$props.maxRangeInDays);
                })
            }
        },
        handleInput(event) {
            this.$nextTick(() => {
                this.currentMinDate = this.originalMinDate;
                this.currentMaxDate = this.originalMaxDate;
            })
        },
    },
}
</script>
