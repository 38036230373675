<template>
    <div class="card card-pdv">
        <PageHeader :title="title" :show-slot="displayMoreButton && !busy">
            <a v-if="displayMoreButton" href="javascript: void(0);" @click="handleModal">
                <i class="fe-more-horizontal icon-blue" />
            </a>
        </PageHeader>
        <div class="card-body">
            <b-row v-if="busy">
                <b-col md="12">
                    <b-skeleton type="button" width="100%" height="60px"></b-skeleton>
                </b-col>
            </b-row>
            <b-row v-else>
                <b-col md="12" class="text-center">
                    <label class="main-text" :style="mainTextStyle">{{ valueFormatted }}</label>
                    <label v-if="showTotalRecords" class="complement-text">{{ getI18n('PAGINATION', 'of') }} {{ padLeft(totalRecords) }}</label>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import PageHeader from '@/src/components/PageHeader.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters'

export default {
    components: {
        PageHeader,
    },
    mixins: [
        vuetableFormatters,
    ],
    props: {
        title: {
            type: String,
            required: true,
        },
        busy: {
            type: Boolean,
            required: false,
        },
        displayMoreButton: {
            type: Boolean,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: '',
        },
        value: {
            type: Number,
            required: false,
            default: 0,
        },
        showTotalRecords: {
            type: Boolean,
            required: false,
        },
        totalRecords: {
            type: Number,
            required: false,
            default: 0,
        },
        textColor: {
            type: String,
            required: false,
            default: '#2E7FEB',
        }
    },
    data () {
      return {}
    },
    computed: {
        mainTextStyle() {
            return {
                '--color': this.textColor
            }
        },
        valueFormatted() {
            if (this.type === 'currency') {
                return this.getI18nCurrency(this.value)
            }
            else if (this.type === 'percentage') {
                return `${this.padLeft(this.value)}%`
            }

            return this.padLeft(this.value)
        },
    },
    methods: {
        handleModal() {
            if (this.busy) {
                return
            }

            this.$emit('onClick')
        },
    },
}
</script>
<style>
.main-text {
    color: var(--color);
    font-family: 'Exo2-Bold', sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 120%;
}
.complement-text {
    margin-left: 5px;
    color: #66686D;
    font-family: 'Exo2', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.02em;
}
</style>
