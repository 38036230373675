<script>
const i18nKey = 'POS'

export default {
    data() {
        return {
            storeProfileTypes: [
                {
                    text: this.storeProfileTypeToText('high_turnover'),
                    value: 'high_turnover',
                },
                {
                    text: this.storeProfileTypeToText('medium_turnover'),
                    value: 'medium_turnover',
                },
                {
                    text: this.storeProfileTypeToText('low_turnover'),
                    value: 'low_turnover',
                },
            ],
        }
    },

    methods: {
        storeProfileTypeToText(value) {
            return value ? this.getI18n(i18nKey, `TYPES.${value}`) : null
        },
    },
}
</script>
