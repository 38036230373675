import BaseService from '@src/services/BaseService'

class CostServingService extends BaseService {
    constructor() {
    super('cost-serving')
    }

    resume(params) {
        return this.axios.post(`${this.getUrl()}/resume`, params)
    }
}

export default new CostServingService()
