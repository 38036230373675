<template>
    <div>
        <b-row class="justify-content-center">
            <lottie-player
                src="https://assets8.lottiefiles.com/packages/lf20_pbeuiqwp.json"
                background="transparent"
                speed="1.10"
                style="width: 150px; height: 150px;"
                autoplay
                loop
                :title="i18nNoFilterApplied"
            />
        </b-row>
        <b-row class="justify-content-center">
            <label class="text-no-record">
                {{ i18nNoFilterApplied }}
            </label>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        childClass: {
            type: String,
            required: false,
            default: ''
        },
        busy: {
            type: Boolean,
            required: false,
        },
        disabled: {
            type: Boolean,
            required: false,
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
    },
    computed: {
        i18nNoFilterApplied() {
            return this.getI18n('PAGINATION', 'no_filter_applied')
        },
    },
}
</script>

<style scoped>
.icon-no-record {
    font-size: 90px;
}

.text-no-record {
    color: #66686D;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0.05em;
}
</style>
