<script>
import vuetableFormatters from '@src/mixins/vuetableFormatters.vue'

export default {
    mixins: [
        vuetableFormatters
    ],
    methods: {
        formatApportionmentType(value) {
            if (value) {
                return this.getI18n('POS', `FIELDS.${value}`)
            }
            return this.getI18n('POS', 'TITLES.pos_alias')
        },
        formatDailyCost(value) {
            const message = `${this.getI18n('DASHBOARDS', 'TITLES.gross_cost')} / 26 dias`
            return `<div title="${message}">${this.getI18nCurrency(value)}</div>`
        },
        formatHourCost(value) {
            const message = `(${this.getI18n('DASHBOARDS', 'TITLES.gross_cost')} / 26 dias) / ${this.getI18n('USERS', 'FIELDS.hours_daily')}`
            return `<div title="${message}">${this.getI18nCurrency(value)}</div>`
        },
        formatPercentageGrossCost(value) {
            const message = `(${this.getI18n('DASHBOARDS', 'TITLES.gross_cost')} * 100) / ${this.getI18n('POS', 'FIELDS.revenue')}`
            return `<div title="${message}">${this.formatPercentage(value)}</div>`
        },
        formatPeriodCost(value) {
            const message = `(${this.getI18n('DASHBOARDS', 'TITLES.gross_cost')} * ${this.getI18n('DASHBOARDS', 'TITLES.number_visits')}) / 26 dias}`
            return `<div title="${message}">${this.getI18nCurrency(value)}</div>`
        },
        formatPercentagePeriodCost(value) {
            const message = `(((${this.getI18n('DASHBOARDS', 'TITLES.gross_cost')} * ${this.getI18n('DASHBOARDS', 'TITLES.number_visits')}) / 26 dias) * 100) / ${this.getI18n('POS', 'FIELDS.revenue')}`
            return `<div title="${message}">${this.formatPercentage(value)}</div>`
        },
    },
}
</script>
