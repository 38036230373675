<template>
    <form-modal
        ref="formModal"
        :title="title"
        :size="size">
        <template v-slot:form>
            <b-row v-if="searchFields.length > 0">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText" />
                </div>
                <div class="col-sm-6">
                </div>
            </b-row>
            <api-mode-vuetable
                ref="vuetable"
                :fetch-data="fetchData"
                :additional-parameters="parameters"
                :fields="fields"
                :sort-order="sortOrder"
                :per-page="perPage"
            />
         </template>
    </form-modal>
</template>

<script>
import FormModal from '@/src/components/FormModal.vue'
import ApiModeVuetable from '@components/ApiModeVuetable'
import FilterBar from '@components/FilterBar'
import cloneObject from '@utils/clone-object'
import { debounce } from 'lodash'

export default {
    components: {
        FormModal,
        ApiModeVuetable,
        FilterBar,
    },
    props: {
        fetchData: {
            type: Function,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: 'lg',
        },
        fields: {
            type: Array,
            required: true,
        },
        additionalParameters: {
            type: Object,
            required: false,
            default: () => {},
        },
        perPage: {
            type: Number,
            required: false,
            default: 5,
        },
        sortOrder: {
            type: Array,
            default: () => [],
        },
        searchFields: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            data: [],
            filterText: '',
            parameters: []
        }
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.parameters = cloneObject(this.additionalParameters)

            if (this.searchFields.length > 0 && searchText && searchText.length > 0) {
                for (var i = 0; i < this.searchFields.length; i++) {
                    this.parameters.search = `${this.parameters.search ? `${this.parameters.search};` : ''}${this.searchFields[i]}:${searchText}`
                }
            }
            this.refreshVuetable()
        }, 1000),
    },
    methods: {
        show() {
            this.filterText = ''
            this.parameters = cloneObject(this.additionalParameters)
            this.$refs.formModal.show()
        },
        hide() {
            this.$refs.formModal.hide()
        },
        refreshVuetable() {
            this.$nextTick(() => {
                this.$refs.vuetable.refresh()
            })
        },
    },
}
</script>
