<template>
    <data-modal
        ref="dataModal"
        size="xl"
        :fetch-data="fetch"
        :title="titleFormatted"
        :fields="fields"
        :additional-parameters="additionalParameters"
        :sort-order="sortOrder"
    >
    </data-modal>
</template>

<script>
import CostServingService from '@src/services/CostServingService'
import DataModal from '@/src/components/dataModal'
import vuetableFormatters from '@src/mixins/vuetableFormatters'
import costOfServingFormatters from '@src/mixins/intelligence/costOfServing/costOfServingFormatters.vue'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        DataModal,
    },
    mixins: [
        vuetableFormatters,
        costOfServingFormatters
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            sortOrder: [
                {
                    field: 'perc_period_cost',
                    direction: 'desc',
                },
            ],
            additionalParameters: {},
            data: [],
            filterText: '',
            fields: [
                {
                    name: 'name',
                    title: this.getI18n('USERS', 'TITLES.user'),
                    // sortField: 'name',
                },
                {
                    name: 'number_visits',
                    title: this.getI18n(i18nKey, 'TITLES.number_visits'),
                    // sortField: 'number_visits',
                },
                {
                    name: 'hours_daily',
                    title: this.getI18n('USERS', 'FIELDS.hours_daily'),
                    // sortField: 'hours_daily',
                },
                {
                    name: 'monthly_cost',
                    title: this.getI18n(i18nKey, 'TITLES.gross_cost'),
                    // sortField: 'monthly_cost',
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'daily_cost',
                    title: `${this.getI18n(i18nKey, 'TITLES.gross_cost')} (${this.getI18n(i18nCommon, 'day')})`,
                    // sortField: 'daily_cost',
                    formatter: this.formatDailyCost,
                },
                {
                    name: 'hour_cost',
                    title: `${this.getI18n(i18nKey, 'TITLES.gross_cost')} (${this.getI18n(i18nCommon, 'hours')})`,
                    // sortField: 'hour_cost',
                    formatter: this.formatHourCost,
                },
                {
                    name: 'perc_gross_cost',
                    title: `% ${this.getI18n(i18nKey, 'TITLES.gross_cost')}`,
                    // sortField: 'perc_gross_cost',
                    formatter: this.formatPercentageGrossCost,
                },
                {
                    name: 'period_cost',
                    title: this.getI18n(i18nKey, 'TITLES.period_cost'),
                    // sortField: 'period_cost',
                    formatter: this.formatPeriodCost,
                },
                {
                    name: 'perc_period_cost',
                    title: `% ${this.getI18n(i18nKey, 'TITLES.period_cost')}`,
                    // sortField: 'perc_period_cost',
                    formatter: this.formatPercentagePeriodCost,
                },
            ],
            perPage: 5,
            posName: '',
            posRevenue: 0,
        }
    },
    computed: {
        titleFormatted() {
            return `${this.getI18n('POS', 'TITLES.pos_alias')}: ${this.posName} (${this.getI18n('POS', 'FIELDS.revenue')}: ${this.getI18nCurrency(this.posRevenue)})`
        },
    },
    methods: {
        fetch(url, params) {
            return CostServingService.fetchVuetable('list-users', params)
        },
        showModal(posId, posName, posRevenue, parameters) {
            this.posName = posName
            this.posRevenue = posRevenue
            this.additionalParameters = {
                ...parameters,
                point_of_sale_id: posId,
                revenue: posRevenue,
            }

            this.$nextTick(() => {
                this.$refs.dataModal.show()
            })
        },
        hideModal() {
            this.$refs.dataModal.hide()
        },
    },
}
</script>
