<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3">
                <div class="col-sm-8">
                    <b-row>
                        <b-col md="5">
                            <b-form-group
                                :label="i18nPeriod"
                                class="required label-pdv"
                                label-for="filter-date"
                            >
                                <date-range-picker
                                    :value.sync="filters.date"
                                    :max-date="new Date()"
                                    :disabled="loading"
                                    :disable-clean-button="true"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                                :label="i18nBusinessUnit"
                                label-for="filter-business-unit"
                                class="required label-pdv"
                                :invalid-feedback="getI18n('ERROR_CODES.invalid_business_unit')"
                            >
                                <multiSelectWithService
                                    :id="'filter-business-unit'"
                                    ref="businessUnitMultiselect"
                                    v-model="filters.business_unit"
                                    :input-function="$v.filters.business_unit.id.$touch"
                                    :select-class="validationClass($v.filters.business_unit.id)"
                                    :service="'business-units'"
                                    :searchable="true"
                                    :preselect-first="true"
                                    @input="changeBusinessUnit"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="col-sm-4 align-self-center">
                    <div class="d-flex justify-content-between">
                        <more-filters-label-button class="col-sm-6" :disabled="loading" @onClick="showFilterModal" />
                        <filter-button class="col-sm-6 col-md-6" :disabled="loading" @onClick="filter" />
                    </div>
                </div>
            </div>
            <div v-show="loaded && rulesConfigured">
                <b-row class="mb-2">
                    <div class="col-sm-12">
                        <div class="text-sm-right">
                            <rules-label-button @onClick="showRulesModal" @refresh="refresh" />
                            <export-button
                                v-if="$can('CREATE', 'EXPORTJOB')"
                                type="cost_serving"
                                :create-parameters="createResumeParameters"
                            />
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <cost-serving-resume ref="costServingResume" />
                    </b-col>
                </b-row>
                <div class="card card-pdv">
                    <PageHeader :title="i18nPos" />
                    <div class="card-body">
                        <api-mode-vuetable
                            ref="vuetable"
                            :fetch-data="fetch"
                            :fields="fields"
                            :per-page="perPage"
                            :additional-parameters="additionalParameters"
                            :sort-order="sortOrder">
                            <template v-if="filters.business_unit" slot="cost_of_serving" slot-scope="props">
                                <h5>
                                    <b-badge
                                        v-if="props.rowData.perc_period_cost >= filters.business_unit.cost_serving_rule_out_min"
                                        pill
                                        style="background-color: #AF0404;"
                                    >
                                        {{ i18nOutside }}
                                    </b-badge>
                                    <b-badge
                                        v-else-if="props.rowData.perc_period_cost >= filters.business_unit.cost_serving_rule_alert_min"
                                        pill
                                        style="background-color: #EDB012;"
                                    >
                                        {{ i18nAlert }}
                                    </b-badge>
                                    <b-badge
                                        v-else
                                        pill
                                        style="background-color: #2E7FEB;"
                                    >
                                        {{ i18nInside }}
                                    </b-badge>
                                </h5>
                            </template>
                            <template slot="actions" slot-scope="props">
                                <div class="table-button-container">
                                    <more-table-button @onClick="openUsersModal(props.rowData)" />
                                </div>
                            </template>
                        </api-mode-vuetable>
                    </div>
                </div>
            </div>
            <div v-show="loaded && !rulesConfigured">
                <b-row class="justify-content-center">
                    <lottie-player
                        src="https://assets1.lottiefiles.com/packages/lf20_sp05lewr.json"
                        background="transparent"
                        speed="0.60"
                        style="width: 150px; height: 150px;"
                        autoplay
                        loop
                        :title="i18nRulesNotConfigured"
                    />
                </b-row>
                <b-row class="justify-content-center">
                    <label class="text-no-record">
                        {{ i18nRulesNotConfigured }}!
                    </label>
                </b-row>
            </div>
            <div v-show="!loaded">
                <no-filter-applied />
            </div>
        </div>

        <cost-serving-users-modal ref="costServingUsersModal" />
        <cost-serving-filter-modal ref="costServingFilterModal" :handle-submit="filter" />
        <cost-serving-rules-modal ref="costServingRulesModal" />
    </Layout>
</template>

<script>
import CostServingService from '@src/services/CostServingService'

import { required, integer, minValue } from 'vuelidate/lib/validators'
import Layout from '@layouts/main'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import FilterButton from '@/src/components/FilterButton'
import RulesLabelButton from '@/src/components/RulesLabelButton'
import DateRangePicker from '@/src/components/DateRangePicker'
import costServingFilterModal from '@src/router/views/intelligence/costOfServing/costServingFilterModal.vue'
import costServingResume from '@src/router/views/intelligence/costOfServing/costServingResume.vue'
import costServingRulesModal from '@src/router/views/intelligence/costOfServing/costServingRulesModal.vue'
import costServingUsersModal from '@src/router/views/intelligence/costOfServing/costServingUsersModal.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import storeProfileTypes from '@src/mixins/intelligence/costOfServing/storeProfileTypes'
import costOfServingFormatters from '@src/mixins/intelligence/costOfServing/costOfServingFormatters.vue'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import NoFilterApplied from '@src/components/NoFilterApplied.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import validationMixin from '@src/mixins/validation.vue'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        MoreFiltersLabelButton,
        FilterButton,
        RulesLabelButton,
        DateRangePicker,
        costServingFilterModal,
        costServingResume,
        costServingRulesModal,
        costServingUsersModal,
        multiSelectWithService,
        MoreTableButton,
        NoFilterApplied,
        ExportButton,
    },
    mixins: [
        filterVuetable,
        vuetableFormatter,
        storeProfileTypes,
        costOfServingFormatters,
        validationMixin,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            rulesConfigured: true,
            loaded: false,
            loading: false,
            perPage: 10,
            additionalParameters: {},
            sortOrder: [
                {
                    field: 'perc_period_cost',
                    direction: 'desc',
                },
            ],
            fields: [
                {
                    name: 'external_code',
                    title: this.getI18n('POS', 'FIELDS.external_code'),
                    // sortField: 'external_code',
                },
                {
                    name: 'name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                    // sortField: 'name',
                },
                {
                    name: 'regional_name',
                    title: this.getI18n('POS', 'TITLES.regional'),
                    // sortField: 'regional_name',
                },
                {
                    name: 'chain_name',
                    title: this.getI18n('POS', 'TITLES.chain'),
                    // sortField: 'chain_name',
                },
                {
                    name: 'ranking',
                    title: this.getI18n('POS', 'IMPORT_FIELDS.ranking'),
                    // sortField: 'ranking',
                },
                {
                    name: 'profile',
                    title: this.getI18n('POS', 'FIELDS.store_profile'),
                    // sortField: 'profile',
                    formatter: this.storeProfileTypeToText,
                },
                {
                    name: 'number_checkout',
                    title: this.getI18n('POS', 'FIELDS.number_checkout'),
                    // sortField: 'number_checkout',
                },
                {
                    name: 'apportionment_type',
                    title: this.getI18n(i18nKey, 'TITLES.apportionment_type'),
                    // sortField: 'apportionment_type',
                    formatter: this.formatApportionmentType,
                },
                {
                    name: 'revenue',
                    title: this.getI18n('POS', 'FIELDS.revenue'),
                    // sortField: 'revenue',
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'number_visits',
                    title: this.getI18n(i18nKey, 'TITLES.number_visits'),
                    // sortField: 'number_visits',
                },
                {
                    name: 'gross_cost',
                    title: this.getI18n(i18nKey, 'TITLES.gross_cost'),
                    // sortField: 'gross_cost',
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'perc_gross_cost',
                    title: `% ${this.getI18n(i18nKey, 'TITLES.gross_cost')}`,
                    // sortField: 'perc_gross_cost',
                    formatter: this.formatPercentageGrossCost,
                },
                {
                    name: 'period_cost',
                    title: this.getI18n(i18nKey, 'TITLES.period_cost'),
                    // sortField: 'period_cost',
                    formatter: this.formatPeriodCost,
                },
                {
                    name: 'perc_period_cost',
                    title: `% ${this.getI18n(i18nKey, 'TITLES.period_cost')}`,
                    // sortField: 'perc_period_cost',
                    formatter: this.formatPercentagePeriodCost,
                },
                {
                    name: 'cost_of_serving',
                    title: this.getI18n(i18nKey, 'TITLES.cost_of_serving'),
                    titleClass: 'text-center aligned',
                    dataClass: 'text-center aligned',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            filters: {
                date: {
                    start: moment().startOf('day').add(-30, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                business_unit: null,
                regionals: [],
                chains: [],
                channels: [],
            },
        }
    },
    validations: {
        filters: {
            business_unit: {
                id: {
                    required,
                    integer,
                    minValue: minValue(1)
                },
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18n(i18nCommon, 'intelligence'),
                    active: true,
                },
                {
                    text: this.getI18n(i18nKey, 'TITLES.cost_of_serving'),
                    active: true,
                },
            ]
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nInside() {
            return this.getI18n(i18nCommon, 'inside')
        },
        i18nAlert() {
            return this.getI18n(i18nCommon, 'alert')
        },
        i18nOutside() {
            return this.getI18n(i18nCommon, 'outside')
        },
        i18nRulesNotConfigured() {
            return this.getI18n('BUSINESS_UNITS', 'MESSAGES.rules_not_configured')
        },
        i18nPos() {
            return this.getI18nModified({
                prefix: 'POS',
                suffix: 'TITLES.pos',
                modifier: 2,
            })
        },
    },
    mounted() {
        this.resetValidation()
    },
    methods: {
        changeBusinessUnit() {
            this.loaded = false
            this.rulesConfigured = true
        },
        createResumeParameters() {
            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                due_date: this.filters.due_date && this.filters.due_date.value ? this.filters.due_date.value : null,
                business_unit_id: this.filters.business_unit ? this.filters.business_unit.id : null,
                regional_id: this.filters.regionals.map((regional) => regional.id),
                chain_id: this.filters.chains.map((chain) => chain.id),
                channel_id: this.filters.channels.map((channel) => channel.id),
                product_category_id: this.filters.product_categories,
                start: period.from,
                end: period.to,
            })
        },
        filter(appliedFilters) {
            this.$v.filters.$touch()
            if (appliedFilters || (!this.$v.filters.$invalid && !this.loading)) {
                if (appliedFilters) {
                    this.filters = appliedFilters
                }
                this.additionalParameters = this.createResumeParameters()
                this.refresh()
            }
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.costServingFilterModal.showModal(this.filters))
        },
        showRulesModal() {
            this.$nextTick(() => this.$refs.costServingRulesModal.showModal(this.filters.business_unit))
        },
        fetch(url, params) {
            return CostServingService.fetchVuetable('list-pos', params)
        },
        refresh() {
            this.loaded = false
            if (!this.configuredRules()) {
                this.loaded = true
                return;
            }

            this.loading = true

            this.$refs.costServingResume.refresh(this.additionalParameters)

            this.refreshTable()

            this.loading = false
            this.loaded = true
        },
        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },
        openUsersModal(data) {
            this.$nextTick(() => this.$refs.costServingUsersModal.showModal(data.id, data.name, data.revenue, this.additionalParameters))
        },
        configuredRules() {
            this.rulesConfigured = true
            if (!this.filters.business_unit) {
                this.rulesConfigured = false
            }
            else if (this.filters.business_unit.cost_serving_rule_alert_min <= 0 || this.filters.business_unit.cost_serving_rule_out_min <= 0) {
                this.rulesConfigured = false
            }
            else if (!this.filters.business_unit.chain_billing_apportionment_type) {
                this.rulesConfigured = false
            }

            return this.rulesConfigured
        },
    }
}
</script>
