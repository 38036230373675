<template>
    <button
        type="button"
        class="btn btn-pdv btn-pdv-blue mr-1"
        href="javascript: void(0);"
        :disabled="disabled"
        @click="$emit('onClick')"
    >
        <i class="fe-settings mr-1"></i>
        {{ i18nRules }}
    </button>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            require: false,
        },
    },
    computed: {
        i18nRules() {
            return this.getI18nModified({
                suffix: 'rule',
                prefix: 'COMMON',
                modifier: 2,
            })
        },
    }
}
</script>
