<template>
    <b-row>
        <b-col md="4">
            <indicator-card
                ref="chartPosInside"
                class="col-sm-12"
                :title="i18nInside"
                :busy="loading"
                :value="posInside.length"
                :display-more-button="true"
                @onClick="openModalPosInside"
            />
        </b-col>
        <b-col md="4">
            <indicator-card
                ref="chartPosAlert"
                class="col-sm-12"
                :title="i18Alert"
                :busy="loading"
                :value="posAlert.length"
                :text-color="'#EDB012'"
                :display-more-button="true"
                @onClick="openModalPosAlert"
            />
        </b-col>
        <b-col md="4">
            <indicator-card
                ref="chartPosOutside"
                class="col-sm-12"
                :title="i18nOutside"
                :busy="loading"
                :value="posOutside.length"
                :text-color="'#AF0404'"
                :display-more-button="true"
                @onClick="openModalPosOutside"
            />
        </b-col>
        <grid-form-modal
            ref="gridPosInsideModal"
            :items="posInside"
            :fields="posCostServingChildrenFields"
            :title="i18nInside"
        />
        <grid-form-modal
            ref="gridPosAlertModal"
            :items="posAlert"
            :fields="posCostServingChildrenFields"
            :title="i18Alert"
        />
        <grid-form-modal
            ref="gridPosOutsideModal"
            :items="posOutside"
            :fields="posCostServingChildrenFields"
            :title="i18nOutside"
        />
    </b-row>
</template>

<script>
import CostServingService from '@src/services/CostServingService'

import IndicatorCard from '@/src/components/dashboards/IndicatorCard.vue'
import GridFormModal from '@src/components/GridFormModal.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import posCostServingChildrenFields from '@src/fields/childrens/posCostServingChildrenFields.vue'

const i18nCommon = 'COMMON'

export default {
    components: {
        IndicatorCard,
        GridFormModal,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        posCostServingChildrenFields,
    ],
    data() {
        return {
            i18nCommon,
            loading: false,
            posInside: [],
            posAlert: [],
            posOutside: [],
        }
    },
    validations: {},
    computed: {
        i18nPos() {
            return this.getI18nModified({
                suffix: 'TITLES.pos_alias',
                prefix: 'POS',
                modifier: 2,
            })
        },
        i18nInside() {
            return `${this.i18nPos} ${this.getI18n(i18nCommon, 'inside')}`
        },
        i18Alert() {
            return `${this.i18nPos} ${this.getI18n(i18nCommon, 'alert')}`
        },
        i18nOutside() {
            return `${this.i18nPos} ${this.getI18n(i18nCommon, 'outside')}`
        },
    },
    methods: {
        refresh(parameters) {
            this.loading = true
            this.posInside = []
            this.posAlert = []
            this.posOutside = []

            CostServingService.resume(parameters)
                .then((response) => {
                    this.posInside = response.data.in
                    this.posAlert = response.data.alert
                    this.posOutside = response.data.out

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
        openModalPosInside() {
            this.$nextTick(() => this.$refs.gridPosInsideModal.showModal())
        },
        openModalPosAlert() {
            this.$nextTick(() => this.$refs.gridPosAlertModal.showModal())
        },
        openModalPosOutside() {
            this.$nextTick(() => this.$refs.gridPosOutsideModal.showModal())
        },
    },
}
</script>
