<template>
    <form-modal
        ref="formModal"
        :title="i18nRules">
        <template v-slot:form>
            <form>
                <b-row>
                    <b-col md="2" class="text-center">
                        <b>{{ i18nInside }}</b>
                    </b-col>
                    <b-col md="2" class="text-center">
                        <b>{{ i18Alert }}</b>
                    </b-col>
                    <b-col md="2" class="text-center">
                        <b>{{ i18nOutside }}</b>
                    </b-col>
                    <b-col md="6" class="text-center">
                        <b>{{ i18nChainBillingApportionmentType }}</b>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="2" class="text-center">
                        +{{ businessUnit.cost_serving_rule_in_min }}%
                    </b-col>
                    <b-col md="2" class="text-center">
                        +{{ businessUnit.cost_serving_rule_alert_min }}%
                    </b-col>
                    <b-col md="2" class="text-center">
                        +{{ businessUnit.cost_serving_rule_out_min }}%
                    </b-col>
                    <b-col md="6" class="text-center">
                        {{ chainBillingApportionmentTypeToText(businessUnit.chain_billing_apportionment_type) }}
                    </b-col>
                </b-row>
            </form>
        </template>
    </form-modal>
</template>

<script>

import FormModal from '@/src/components/FormModal.vue'
import chainBillingApportionmentTypes from '@src/mixins/intelligence/costOfServing/chainBillingApportionmentTypes'

const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
    },
    mixins: [
        chainBillingApportionmentTypes
    ],
    data() {
        return {
            i18nCommon,
            businessUnit: {
                id: null,
                name: null,
            },
        }
    },
    validations: {},
    computed: {
        i18nRules() {
            return this.getI18nModified({
                suffix: 'rule',
                prefix: i18nCommon,
                modifier: 2,
            })
        },
        i18nInside() {
            return this.getI18n(i18nCommon, 'inside')
        },
        i18Alert() {
            return this.getI18n(i18nCommon, 'alert')
        },
        i18nOutside() {
            return this.getI18n(i18nCommon, 'outside')
        },
        i18nChainBillingApportionmentType() {
            return this.getI18n('BUSINESS_UNITS', 'TITLES.chain_billing_apportionment_type')
        }
    },
    methods: {
        showModal(businessUnit) {
            this.businessUnit = businessUnit
            this.$refs.formModal.show()
        },
    },
}
</script>
