<script>
const i18nKey = 'BUSINESS_UNITS'

export default {
    data() {
        return {
            chainBillingApportionmentTypes: [
                {
                    text: this.chainBillingApportionmentTypeToText('store_profile'),
                    value: 'store_profile',
                },
                // {
                //     text: this.chainBillingApportionmentTypeToText('store_ranking'),
                //     value: 'store_ranking',
                // },
                {
                    text: this.chainBillingApportionmentTypeToText('number_checkout'),
                    value: 'number_checkout',
                },
            ],
        }
    },

    methods: {
        chainBillingApportionmentTypeToText(value) {
            return value ? this.getI18n(i18nKey, `TYPES.${value}`) : null
        },
    },
}
</script>
